<template>
  <v-card>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selected.length > 0"
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="deleteMultipleItems"
        >{{ $t('operations.delete') }} ({{ selected.length }})
      </v-btn>
      <v-btn
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="exportItems"
        >{{ $t('operations.export') }}
      </v-btn>
      <v-btn color="teal dark" dark class="mx-1" @click="newItem">
        {{ $t('operations.new') }}
      </v-btn>
    </v-toolbar>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        autocomplete="off"
      >
        <template v-slot:label>{{ $t('operations.search') }} </template>
      </v-text-field>
    </v-card-title>
    <!-- table -->
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :single-select="false"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: $t('operations.items_per_page')
      }"
      sort-by="id"
      show-select
    >
      <template v-slot:loading>{{ $t('operations.loading') }} </template>
      <template v-slot:[`item.options`]="{ item }">
        <div>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="editItem(item)"
            >{{ $t('operations.edit') }}
          </v-btn>

          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="deleteItem(item)"
            >{{ $t('operations.delete') }}
          </v-btn>
          <v-btn
            color="blue-grey darken-2"
            class="mx-1"
            dark
            small
            @click="setPassword(item)"
            >{{ $t('operations.password') }}
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue-grey darken-2"
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('operations.configure') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click="itemGroups(item)">
                  {{ $t('operations.groups') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="itemDependencies(item)">
                  {{ $t('operations.dependencies') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="itemLocations(item)">
                  {{ $t('operations.training_locations') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <!-- form -->
    <AppDialogForm ref="dialogForm" :isValid="isValid">
      <v-form ref="form" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="item.username"
              :rules="formRules"
              autocomplete="off"
              autofocus
              required
            >
              <template v-slot:label>{{ $t('fields.username') }} </template>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="item.first_name"
              :rules="formRules"
              autocomplete="off"
              required
            >
              <template v-slot:label>{{ $t('fields.first_name') }} </template>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="item.last_name"
              :rules="formRules"
              autocomplete="off"
              required
            >
              <template v-slot:label>{{ $t('fields.last_name') }} </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="item.email"
              :rules="formRules"
              autocomplete="off"
            >
              <template v-slot:label>{{ $t('fields.email') }} </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <!-- password -->
    <AppDialogForm ref="dialogPassword" :isValid="isValid">
      <v-form ref="passwordForm" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="password"
              v-model="item.password"
              :rules="[rules.required, rules.min]"
              autocomplete="off"
              autofocus
              required
            >
              <template v-slot:label>{{ $t('fields.password') }} </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <!-- configurations -->
    <AppDialogGroups ref="dialogGroups" />
    <AppDialogDependencies ref="dialogDependencies" />
    <AppDialogLocations ref="dialogLocations" />
    <!-- confirm -->
    <AppDialogConfirm ref="confirm" />
  </v-card>
</template>

<script>
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
import AppDialogForm from '@/components/dialogs/Form.vue'
import AppDialogGroups from './UserGroups'
import AppDialogDependencies from './UserDependencies'
import AppDialogLocations from './UserLocations'
export default {
  name: 'Users',
  components: {
    AppDialogConfirm,
    AppDialogForm,
    AppDialogGroups,
    AppDialogDependencies,
    AppDialogLocations
  },
  data: () => ({
    name: null,
    url: null,
    url_file: null,
    url_groups: null,
    headers: [],
    formRules: [(v) => !!v || 'Required'],
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => (v && v.length >= 8) || 'Min 8 characters'
    },
    items: [],
    groups: [],
    item: {},
    selected: [],
    loading: false,
    loading_text: null,
    isSelecting: false,
    search: null,
    isValid: false
  }),

  created() {
    this.url = 'security/users/'
    this.url_file = 'security/users/file/'

    this.name = this.$t('options.security.users')
    this.headers = [
      { text: 'Id', value: 'id' },
      { text: this.$t('fields.username'), value: 'username' },
      { text: this.$t('fields.first_name'), value: 'first_name' },
      { text: this.$t('fields.last_name'), value: 'last_name' },
      { text: this.$t('fields.email'), value: 'email' },
      { text: this.$t('fields.options'), value: 'options', align: 'right' }
    ]
    this.getItems()
  },

  methods: {
    getItems() {
      this.loading = true
      this.$http.get(this.url).then((request) => {
        this.loading = false
        this.items = request.data
      })
    },

    newItem() {
      let item = {}
      this.item = Object.assign({}, item)
      let options = { color: 'teal' }
      let title = this.$t('operations.new').toUpperCase()
      this.$refs.dialogForm.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate())
          this.$http
            .post(this.url, JSON.stringify(this.item))
            .then(() => this.getItems())
      })
    },

    editItem(item) {
      this.item = Object.assign({}, item)
      let options = { color: 'teal' }
      let title = `${this.$t('operations.editing').toUpperCase()} [${item.id}]`
      this.$refs.dialogForm.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate())
          this.$http
            .put(`${this.url}?id=${this.item.id}`, JSON.stringify(this.item))
            .then(() => this.getItems())
      })
    },

    deleteItem(item) {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let messaje = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, messaje, options).then((confirm) => {
        if (confirm)
          this.$http
            .delete(`${this.url}?id=${item.id}`)
            .then(() => this.getItems())
      })
    },

    async deleteMultipleItems() {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let messaje = this.$t('dialogs.confirm_text')
      let confirm = await this.$refs.confirm.open(title, messaje, options)
      if (await confirm) {
        var processed = 0
        this.selected.forEach(async (item) => {
          await this.$http.delete(`${this.url}?id=${item.id}`).then(() => {
            processed++
            if (processed === this.selected.length) {
              this.getItems()
            }
          })
        })
      }
    },

    exportItems() {
      this.$http
        .get(this.url_file, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${this.name}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },

    setPassword(item) {
      this.item = Object.assign({}, item)
      let options = { color: 'teal' }
      let title = `${this.$t('operations.password').toUpperCase()} [${item.id}]`
      this.$refs.dialogPassword.open(title, options).then((confirm) => {
        if (confirm && this.$refs.passwordForm.validate()) {
          var data = { user_id: this.item.id, password: this.item.password }
          this.$http
            .put(`${this.url}password/`, JSON.stringify(data))
            .then(() => {
              delete this.item.password
            })
        }
      })
    },

    itemGroups(item) {
      this.$refs.dialogGroups.open(item)
    },
    itemDependencies(item) {
      this.$refs.dialogDependencies.open(item)
    },
    itemLocations(item) {
      this.$refs.dialogLocations.open(item)
    }
  }
}
</script>

<style></style>
