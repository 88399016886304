<template>
  <AppDialog ref="dialog">
    <v-card cols="3" v-for="(obj, index) in items" :key="index">
      <v-list>
        <v-subheader>
          {{ $t(`modules.${obj.name}`) }}
        </v-subheader>
        <v-list-item-group multiple>
          <v-list-item
            v-for="(option, idx) in obj.options"
            :key="idx"
            @change="changePermission(option)"
          >
            <v-list-item-icon>
              <v-icon>{{ option.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`options.${obj.name}.${option.name}`) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="option.has_perm"></v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
    </v-card>
  </AppDialog>
</template>

<script>
import AppDialog from '@/components/dialogs/FullScreen.vue'
export default {
  name: 'GroupPermissions',
  components: { AppDialog },
  data: () => ({ url: null, items: [] }),

  created() {
    this.url = 'security/group/permissions/'
  },

  methods: {
    open(item) {
      let options = { color: 'teal' }
      let title = String(item.name).toUpperCase()
      this.item = item
      this.$http.get(`${this.url}?group_id=${item.id}`).then((response) => {
        this.items = response.data
        this.$refs.dialog.open(title, options).then(() => {})
      })
    },

    changePermission(option) {
      option.has_perm = !option.has_perm
      option.group_id = this.item.id
      this.$http.post(this.url, JSON.stringify(option))
    }
  }
}
</script>

<style>
</style>